@import './components/header/index.styl'
@import './components/mobile_menu/index.styl'
@import '~react-toastify/dist/ReactToastify.css'

.Toastify__toast--default
  background-color #8CACAC
  color #FFFFFF

.Toastify__progress-bar--default
  background #678383

.modal-content__shopping-notification
  padding-left 32px
  padding-right 32px