@import './components/items_summary/index.styl'
@import './components/tipping_section/components/tip_option/index.styl'
@import './components/tipping_section/index.styl'

.tipping-warning-effect {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

html.hide-minimal-fixed-footer-mobile
  +below(mobile-cutoff)
    footer
      display none
    body
      margin-bottom 130px

.review-order-view

  .alcohol-notification
    color: $tangerine
    .icon
      color: $tangerine
      margin-right 3px
      margin-top -3px

  .can-leave-at-door
    .icon
      color: $avocado
      margin-right 3px
      margin-top -3px

  .below-title
    overflow hidden
    display none
    margin-bottom $gutter
    padding-bottom 2px
    .everything-looks-good
      body-1()
      float left
      margin-top 6px

    .place-order
      margin-top 0
      float right
      min-width 210px

  .desktop-checkout-panel
    display none

  +above(mobile-cutoff)
    .desktop-checkout-panel
      display block
      overflow hidden
      padding-bottom 2px
      .place-order
        margin-bottom 0
        float right
        min-width 210px

    .below-title
      display block

  .mobile-checkout-panel
    +above(mobile-cutoff)
      display none

  +below(mobile-cutoff)
    .order
      margin-bottom 110px

  .order .form-error
    clear both
    +below(mobile-cutoff)
      display none

  .fixed-checkout-panel
    border-top 1px solid $oyster
    -webkit-box-shadow inset 0px 1px 0px 0px rgba(255,255,255,0.85);
    box-shadow inset 0px 1px 0px 0px rgba(255,255,255,0.85);
    background-color $pearl
    position fixed
    bottom 0
    left 0
    right 0
    padding 0 $gutter*2 $gutter
    width 100%
    box-sizing border-box
    +above(mobile-cutoff)
      display none

    .receipt
      margin-top $gutter
      margin-bottom $gutter
      .totals__total-label
        body-3()

  .masquerade-overrides
    .ok()
      color $avocado
    .notice
      color $tomato
.remove-promo 
  color $laurel
  cursor pointer
  &:hover
    opacity 0.5