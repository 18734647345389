// overlay-box

overlay-box()
  border-radius 3px
  box-shadow 0 2px 6px 0 rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.1)
  z-index 100000
  background white
  color $black

overlay-box-triangle()
  content ' '
  box-shadow -2px -2px 2px 0 rgba(0,0,0,0.1), -1px -1px 0 0 rgba(0,0,0,0.2)
  background $ricotta
  position absolute
  top -5px
  left 20%
  width 10px
  height 10px
  transform rotate(45deg)
